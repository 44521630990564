<template lang="">
    <div>
        <div>
            <b-card no-body>
                <b-tabs card>
                    <div class="col-md-10 w-100 mx-auto">
                        <b-tab title="Liste de Creatives">
                            <b-row>
                                <b-col cols="6">
                                    <b-form-group label="PUBID" label-for="input-pub-id">
                                        <div>
                                            <b-form-input class="mt-auto" id="id" v-model="pubid"></b-form-input>
                                        </div>
                                    </b-form-group>
                                    <b-form-group>
                                        <b-btn variant="success" class="mt-auto" @click="searchCreative()" :disabled="loading || pubid.trim() === ''">
                                            <span>Rechercher</span>
                                        </b-btn>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-card-text>
                                <b-table v-if="!loading && creative.length > 0" cols="3" striped hover :items="creative" :fields="fields" :responsive="true">
                                    <template #cell(status)="data">
                                        <span :class="getStatusClass(data.item.status)" v-b-tooltip.hover :title="getToolTip(data.item.status)">
                                            {{ data.item.status }}
                                        </span>
                                    </template>
                                    <template #cell(statusText)="data">
                                        {{ getStatusText(data.item.status) }}
                                    </template>
                                </b-table>
                                <div v-else-if="loading">
                                    <div>
                                        <b-spinner class="m-5" label="Busy"></b-spinner>
                                    </div>
                                </div>
                                <div v-else>
                                    <p>No data available.</p>
                                </div>
                            </b-card-text>
                        </b-tab>
                    </div>
                </b-tabs>
            </b-card>
        </div>
        <div v-if="loading" class="overlay">
            <div class="spinner"></div>
        </div>
    </div>
</template>

<script>
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

    export default {
        components: {},
        data() {
            return {
                loading: false,
                creative: [],
                pubid: '',
                fields: ['filmId', 'title', 'pubId', 'acceptationDate', 'status', 'statusDetails'],
                tooltip: '',
            };
        },
        computed: {},

        methods: {
            async searchCreative() {
                try {
                    this.loading = true;
                    const res = await this.$store.dispatch('app/getCreativesBasedOnPubId', {
                        pubid: this.pubid,
                    });
                    if (res.status != 500) {
                        this.creative = res._embedded.creatives.map((item) => ({ ...item, statusDetails: this.getStatusText(item.status) }));
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    this.loading = false;
                }
            },
            getStatusClass(status) {
                const badgeMapping = {
                    300: 'bg-red',
                    250: 'bg-green',
                    190: 'bg-yellow',
                    120: 'bg-gray',
                };
                if (status in badgeMapping) {
                    return badgeMapping[status];
                }
                return 'bg-default';
            },

            getStatusText(status) {
                switch (parseInt(status)) {
                    case 300:
                        return 'Créative Non conforme (Recommencer le processus avec une nouvelle créative)';
                    case 250:
                        return 'Créative conforme (OK pour diffusion)';
                    case 190:
                        return 'Créative en attente de vérification de conformité coté France TV Publicité';
                    case 120:
                        return 'Créative prête à être associer à une campagne';
                    default:
                        return 'status inconnu';
                }
            },
            getToolTip(status) {
                return this.getStatusText(status);
            },
        },
    };
</script>

<style>
    .message-tooltip {
        white-space: normal;
    }

    .bg-yellow {
        background-color: yellow;
        padding: 3px;
    }

    .bg-red {
        background-color: red;
        padding: 3px;
    }

    .bg-gray {
        background-color: purple;
        padding: 3px;
    }

    .bg-green {
        background-color: green;
        padding: 3px;
    }
</style>
